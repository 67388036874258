var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SidePanel2',{attrs:{"isLoading":_vm.isLoading},on:{"close":function($event){return _vm.$emit('close')}}},[_c('SidePanelNavigator',{ref:"navigator",attrs:{"navigatorHtmlId":"lego-side-panel"},on:{"navigation-changed":_vm.handleNavigationChange},scopedSlots:_vm._u([{key:"home",fn:function(){return [_c('FooterWrapper',{attrs:{"primaryButtonLabel":'Save',"scrolls":"","hideFooter":_vm.tab !== 0},on:{"clickPrimary":_vm.save,"clickSecondary":function($event){return _vm.$emit('close')}}},[_c('TabWrapper',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('Tab',{attrs:{"label":"Configure"}},[(_vm.originalLego !== null && _vm.newLego !== null)?_c('ConfigureLegoLevel',{attrs:{"closeOnPrimaryClick":true,"legoPath":_vm.legoPath,"kitDoll":_vm.kitDoll,"directoriesProp":_vm.directories,"chosenDirectoryId":_vm.chosenDirectoryId,"propOriginalLego":_vm.originalLego,"propNewLego":_vm.newLego},on:{"update:propNewLego":function($event){_vm.newLego=$event},"update:prop-new-lego":function($event){_vm.newLego=$event},"createNewDir":function($event){return _vm.navigate('push', 'create-directory')},"close":function($event){return _vm.$emit('close')},"save":function($event){_vm.$emit('save', $event);
                  _vm.$emit('close');},"updateDirectoryId":function($event){_vm.chosenDirectoryId = $event}}}):_vm._e()],1),_c('Tab',{attrs:{"label":"Decorations"}},[(_vm.originalLego.outputCols.length === 0)?_c('div',[_c('div',{staticClass:"tw-flex tw-flex-col tw-items-center tw-gap-y-3"},[_c('div',[_vm._v(" Flank hasn't detected any output columns for this command yet ")]),_c('b-button',{attrs:{"type":_vm.runInNewTabBtnClicked ? '' : 'is-primary'},on:{"click":function ($event) {
                        _vm.openCommandInNewTab();
                        _vm.runInNewTabBtnClicked = true;
                      }}},[_vm._v("Run in New Tab")]),(_vm.runInNewTabBtnClicked)?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":_vm.refreshKitDoll}},[_vm._v("Refresh")]):_vm._e()],1)]):(_vm.decorations.length === 0)?_c('div',{staticClass:"tw-flex tw-flex-col tw-items-center tw-gap-y-3"},[_c('div',{staticClass:"tw-text-center"},[_vm._v(" You haven't created any decorations yet. ")]),_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.navigate('push', 'choose-decoration-name')}}},[_vm._v("Create Decoration")])],1):_c('div',[_c('div',{staticClass:"tw-flex tw-flex-row tw-justify-end tw-mb-3"},[_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.navigate('push', 'choose-decoration-name')}}},[_vm._v("Create Decoration")])],1),_c('b-table',{attrs:{"data":_vm.decorations,"hoverable":"","row-class":function (row, idx) { return 'hover:tw-cursor-pointer'; }},on:{"click":function (row) {
                      _vm.decorationz.drilldown = row;
                      _vm.navigate('push', 'decoration-drilldown');
                    }}},[_c('b-table-column',{attrs:{"label":"To Command"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(_vm._s(props.row.inputKitName))]}}])}),_c('b-table-column',{attrs:{"label":"Button Text"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(_vm._s(props.row.name))]}}])})],1)],1)])],1)],1)]},proxy:true},{key:"create-directory",fn:function(){return [_c('FooterWrapper',{attrs:{"primaryButtonLabel":'Save',"scrolls":""},on:{"clickPrimary":_vm.createDirectory,"clickSecondary":function($event){return _vm.navigate('pop')}}},[_c('HeaderBody',{attrs:{"headerText":"Create Directory"}},[_c('CreateDirectoryLevel2',{attrs:{"propDirectoryName":_vm.directoryName},on:{"update:propDirectoryName":function($event){_vm.directoryName=$event},"update:prop-directory-name":function($event){_vm.directoryName=$event}}})],1)],1)]},proxy:true},{key:"decoration-drilldown$scrolls",fn:function(){return [_c('HeaderBody',{attrs:{"headerText":("Edit " + (_vm.decorationz.drilldown.name)),"onStack":""}},[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-y-6"}),_c('b-button',{staticClass:"tw-mt-4",attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.deleteDecoration(_vm.decorationz.drilldown)}}},[_vm._v("Delete")])],1)]},proxy:true},{key:"choose-decoration-name",fn:function(){return [_c('FooterWrapper',{attrs:{"primaryButtonLabel":"Choose Command","scrolls":""},on:{"clickPrimary":function($event){_vm.outputColsAreMultiset
              ? _vm.navigate('push', 'choose-decoration-result-set')
              : _vm.navigate('push', 'choose-decoration-command')},"clickSecondary":function($event){return _vm.navigate('pop')}}},[_c('HeaderBody',{attrs:{"headerText":"Choose Decoration Name","onStack":""}},[_c('InputGrid',{attrs:{"flatSchema2":_vm.decorationz.decorationNameSchema},on:{"updateElement":_vm.updateElementValue2}})],1)],1)]},proxy:true},{key:"edit-decoration-name",fn:function(){return [_c('FooterWrapper',{attrs:{"primaryButtonLabel":"Save","scrolls":""},on:{"clickPrimary":_vm.saveDecorationName,"clickSecondary":function($event){return _vm.navigate('pop')}}},[_c('HeaderBody',{attrs:{"headerText":"Edit Decoration Name","onStack":""}},[_c('InputGrid',{attrs:{"flatSchema2":_vm.decorationz.editNameSchema},on:{"updateElement":_vm.updateElementValue4}})],1)],1)]},proxy:true},{key:"choose-decoration-result-set",fn:function(){return [_c('FooterWrapper',{attrs:{"primaryButtonLabel":"Choose","scrolls":""},on:{"clickPrimary":function($event){return _vm.navigate('push', 'choose-decoration-command')},"clickSecondary":function($event){return _vm.navigate('pop')}}},[_c('HeaderBody',{attrs:{"headerText":"Choose Result Set","onStack":""}},[_c('InputGrid',{attrs:{"flatSchema2":_vm.resultSetSchema},on:{"updateElement":_vm.updateElementValue3}})],1)],1)]},proxy:true},{key:"choose-decoration-command$scrolls",fn:function(){return [_c('HeaderBody',{attrs:{"headerText":"Create Decoration","onStack":""}},[_c('b-field',[_c('b-input',{staticClass:"sm:tw-w-2/3",attrs:{"placeholder":"Search","icon":"search","rounded":"","size":"is-small"},model:{value:(_vm.filters.searchString.currentValue),callback:function ($$v) {_vm.$set(_vm.filters.searchString, "currentValue", $$v)},expression:"filters.searchString.currentValue"}})],1),(
              Object.entries(_vm.filters).filter(
                function (ref) {
                            var k = ref[0];
                            var v = ref[1];

                            return v.currentValue !== null && v.currentValue !== '';
              }
              ).length > 0
            )?_c('div',{staticClass:"tw-flex tw-flex-row tw-flex-wrap tw-gap-x-3 tw-gap-y-2 tw-pb-3 md:tw-pt-1"},_vm._l((Object.entries(_vm.filters).filter(
                function (ref) {
                            var k = ref[0];
                            var v = ref[1];

                            return v.currentValue !== null && v.currentValue !== '';
              }
              )),function(ref){
              var filterKey = ref[0];
              var filter = ref[1];
return _c('div',{key:filterKey,staticClass:"tw-px-2 tw-py-0.5 tw-text-sm tw-rounded-full tw-border tw-border-purple-600 tw-bg-gray-50 tw-flex tw-flex-row tw-items-center tw-gap-x-1.5"},[_c('b-icon',{staticClass:"tw-text-purple-600 tw-h-3 tw-w-3",staticStyle:{"height":"12px","width":"12px"},attrs:{"icon":filter.iconLeft,"size":"is-small"}}),_c('div',{staticClass:"tw-font-bold tw-text-purple-600"},[_vm._v(" "+_vm._s(filter.pillDisplay())+" ")]),_c('b-icon',{staticClass:"tw-text-purple-600 hover:tw-text-purple-500 hover:tw-cursor-pointer",attrs:{"icon":"times","size":"is-small"},nativeOn:{"click":function($event){return filter.onClose()}}})],1)}),0):_vm._e(),_c('b-table',{staticClass:"tw-w-full",attrs:{"data":_vm.filteredLibrary,"show-header":true,"hoverable":"","loading":_vm.pipeFromTableIsLoading,"row-class":function (row, idx) { return 'hover:tw-cursor-pointer'; }},on:{"click":function (row) {
                _vm.chosenDecorationCommand = row;
                _vm.navigate('push', 'map-columns');
              }}},[_c('b-table-column',{attrs:{"label":"Name","cell-class":"tw-break-words"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{staticClass:"tw-w-56"},[_vm._v(_vm._s(props.row.name))])]}}])}),_c('b-table-column',{attrs:{"label":"Directory"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{staticClass:"tw-w-56"},[_vm._v(_vm._s(props.row.directoryName == null ? "" : props.row.directoryName))])]}}])}),_c('b-table-column',{attrs:{"label":"Columns"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"tw-flex tw-flex-row tw-flex-wrap tw-gap-x-3 tw-w-56 tw-text-sm tw-justify-end"},_vm._l((props.row.legoInstanceInputs),function(col){return _c('div',{key:col.key},[_c('span',{staticClass:"tw-font-mono"},[_vm._v(_vm._s(col.key))]),(col.typeId)?_c('span',{staticClass:"tw-ml-2 tw-text-blue-600 tw-font-mono"},[_vm._v("("+_vm._s(col.typeId)+")")]):_vm._e()])}),0)]}}])})],1)],1)]},proxy:true},{key:"map-columns",fn:function(){return [_c('FooterWrapper',{attrs:{"secondaryButtonLabel":"Choose Different","primaryButtonLabel":"Save","scrolls":""},on:{"clickPrimary":_vm.saveNewDecoration,"clickSecondary":function($event){return _vm.navigate('pop')}}},[_c('HeaderBody',{attrs:{"headerText":"Map Columns","onStack":""}},[_c('InputGrid',{attrs:{"flatSchema2":_vm.schema2},on:{"updateElement":_vm.updateElementValue}})],1)],1)]},proxy:true}]),model:{value:(_vm.navigationState),callback:function ($$v) {_vm.navigationState=$$v},expression:"navigationState"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }